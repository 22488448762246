import React, { useContext } from "react"
import { Typography, Box } from "@material-ui/core"
import { useStyles } from "./style"
import AuthContext from "../../../context/auth/authContext"

const UserInitials = props => {
    const classes = useStyles(props)
    const { user: { firstName, lastName} } = useContext(AuthContext)

    const initials = firstName.charAt(0) + lastName.charAt(0)
  return (
    
    <Box className={classes.initials}>
       <Typography>{initials}</Typography>
    </Box>
  )
}

export default UserInitials
