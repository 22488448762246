import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  initials: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props => (props.bgColor ? props.bgColor : theme.palette.primary.main),
    borderRadius: "50%",
    width: props => (props.width ? props.width : "2.3rem"),
    height: props => (props.height ? props.height : "2.3rem"),
  },
  initialsWrapper: {
    width: "2.7rem",
    height: "2.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: `1px solid green`,
  },
}))

export { useStyles }
